import React from "react";
import Layout from "../../components/layout";
import Button from "../../components/button";
import { P, TextBlockHeading, PageHeading } from "../../components/typography";

const WomensSexualWellness = () => {
  const textData = [
    {
      header:
        "Transform Your Health with Extracorporeal Magnetic Tissue Treatment (EMTT)",
      body: (
        <>
          <P margin="mb-6 pl-0">
            Experience relief from chronic pain and enhance your quality of
            life.
          </P>
          <P margin="mb-6">
            Are you struggling with chronic musculoskeletal pain or sports
            injuries? Our innovating Extracorporeal Magnetotransduciton Therapy
            (EMTT) offers a cutting-edge solution to revitalize your health and
            restore your body's natural balance.
          </P>
        </>
      )
    },
    {
      header: "What is EMTT?",
      body: (
        <>
          <P margin="mb-6">
            EMTT is a revolutionary, non&#8288;invasive treatment designed to
            target disorders of the musculoskeletal system, including{" "}
            <strong>back pain</strong>, <strong>osteoarthritis</strong>, and{" "}
            <strong>sports-related injuries</strong>. Unlike traditional
            treatments, EMTT uses high-frequency magnetic pulses, delivering
            effective transduction power to enhance your body's healing process.
          </P>
        </>
      )
    },
    {
      header: "How Does EMTT Work?",
      body: (
        <>
          <P margin="mb-6">
            Our EMTT therapy is a comfortable and risk-free experience. During
            the session, a therapy loop is positioned over the affected area
            while you remain fully clothed – ensuring your comfort without the
            need for direct skin contact. The high-energy magnetic pulses
            penetrate deeply, stimulating your body's natural healing
            mechanisms.
          </P>
          <P>
            Each session lasts between 5 and 20 minutes, with a typical course
            involving 6 to 8 sessions. The number of sessions varies based on
            the severity of your condition and your body's response to the
            therapy.
          </P>
        </>
      )
    },
    {
      header: "Benefits of EMTT",
      body: (
        <>
          <ul className="mb-6">
            <li>
              - Targeted Relief: Effectively treats a range of conditions,
              including osteoarthritis, herniated discs, chronic back pain, and
              sports injuries.
            </li>
            <li>
              - Non-Invasive: A comfortable, clothed procedure with no direct
              skin contact required.
            </li>
            <li>
              - Quick Sessions: Each session is brief, fitting conveniently into
              your busy schedule.
            </li>
            <li>
              - Safe and Comfortable: A largely risk-free treatment with minimal
              side effects.
            </li>
          </ul>
        </>
      )
    },
    {
      header: "Ready to Embrace a Pain-Free Life?",
      body: (
        <>
          <P margin="mb-6">Ready to Embrace a Pain-Free Life?</P>
          <P margin="mb-6">
            Don't let chronic pain control your life. Book your appointment
            today and take the first step towards a healthier, more active you
            with our EMTT therapy.
          </P>
          <Button linkTo="/contact-us">Book now</Button>
        </>
      )
    }
  ];

  return (
    <Layout>
      <div className="flex justify-center">
        <div className="text-left">
          <div className="px-6 xs:px-12 sm:px-20 md:px-32 lg:max-w-4xl">
            <div>
              <PageHeading className="mb-4 rounded-sm">
                Women's Sexual Wellness
              </PageHeading>
              <div className="mt-2 italic">
                Enhance Sensitivity, Lubrication, and Overall Sexual Wellness in
                a Comfortable and Safe Way
              </div>
            </div>
          </div>
          <div className="px-6 xs:px-12 sm:px-20 md:px-32 lg:max-w-4xl pt-6">
            <TextBlockHeading wide>
              Experience the Breakthrough in Women's Sexual Health
            </TextBlockHeading>
            <div className="mt-2">
              We provide a revolutionary approach to women's sexual wellness.
              Our advanced shockwave therapy is a non-invasive, painless
              treatment designed to significantly improve blood flow to the
              vaginal area. This innovative method promises to revitalize your
              sexual health, offering:
              <ul className="mt-2">
                <li className="mb-0">
                  - Increased sensitivity and lubrication
                </li>
                <li className="mb-0">
                  - Strengthening and tightening of vaginal tissues
                </li>
                <li className="mb-0">
                  - Improved physical appearance of the treated area
                </li>
                <li className="mb-0">
                  - Enhanced sexual fulfillment and function
                </li>
              </ul>
            </div>
          </div>

          <div className="px-6 xs:px-12 sm:px-20 md:px-32 lg:max-w-4xl pt-6">
            <TextBlockHeading wide>
              The Power of Acoustic Soundwaves: Safe, Effective, Proven
            </TextBlockHeading>
            <div>
              Our therapy harnesses the gentle power of acoustic soundwaves, a
              technique with years of clinical backing. These soundwaves
              stimulate clitoral and vaginal tissues, enhancing blood flow and
              nerve sensitivity. The result? A noticeable improvement in sexual
              pleasure, leading to more frequent and intense orgasms.
            </div>
          </div>
          <div className="px-6 xs:px-12 sm:px-20 md:px-32 lg:max-w-4xl pt-6">
            <TextBlockHeading wide>Your Comfort, Our Priority</TextBlockHeading>
            <div className="grid gap-2">
              <div>
                <strong>Personalized Treatment</strong>: Every session is
                tailored to your unique needs, ensuring maximum comfort and
                efficacy. Our experienced practitioners are dedicated to
                providing a respectful and confidential treatment environment.
              </div>
              <div>
                <strong>Quick and Convenient Sessions</strong>: Each treatment
                involves precise, healing soundwaves directed through clothing,
                targeting the clitoral tissue. Sessions are brief (15-30
                minutes) and entirely painless, with no downtime required.
              </div>
              <div>
                <strong>Long-Lasting Results</strong>: Enjoy the long-lasting
                benefits of our therapy with no need for drugs or lasers.
              </div>
              <br></br>
              <Button linkTo="/contact-us">Book today</Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WomensSexualWellness;
